import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Box } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes, CvtBasicTheme } from '../../global';
import { HtmlContent } from '../../components/basic/html-content';

import { Headline } from '../../components/cvt/data-display/headline';
import { BlockStandard } from '../../components/cvt/layout/block-standard';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import ImpressumTabular from './Impressum_comps/impressumTabular';
import MakeImpressum from './Impressum_comps/Impressum_makeImpressum';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

const ImpressumPage = ({ data }: { data: any }) => {
  const styles = {
    flex: {
      display: 'flex',
      flexDirection: 'row',
      [`@media(max-width: ${DeviceSizes.laptop})`]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '0px',
        margin: '0px',
      },
    },
    haftung: {
      width: '70%',
      [`@media (max-width: ${DeviceSizes.laptop})`]: {
        width: '100%',
        marginTop: '5em',
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  };
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  useEffect(() => {
    MakeImpressum();
  });
  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockStandard
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        bgColor={CvtColorSchema.white}
        zIndex={7}
        padding={PaddingObj.PaddingImpressum}
      >
        <Box component="div" sx={styles.flex}>
          <ImpressumTabular />
          <Box component="div" sx={styles.haftung}>
            <Headline text={frontmatter.title} variant={'h3'} />
            <HtmlContent
              dangerouslySetInnerHTML={md.render(data.markdownRemark.html)}
              style={CvtBasicTheme.htmlContent.general}
            />
          </Box>
        </Box>
      </BlockStandard>
    </Layout>
  );
};

ImpressumPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImpressumPage;

export const impressumPageQuery = graphql`
  query ImpressumPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        seoKeywords
        pageDescription
        pageTitle
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
